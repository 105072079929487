.inventory-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.header {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 10px;
  justify-content: space-around;
}
.product-details {
  max-width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin: 5px;
  box-shadow: 20px 11px 11px -7px rgba(0,0,0,0.1);
}
.product-container {
  display: flex;
  align-items: center;
  margin: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}

.loader{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* style.css */

.cart-container {
  text-align: center;
  margin: 20px;
}

.cart-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cart-item {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 10px;
  width: 300px;
  height: 420px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.cart-item h3 {
  margin: 10px 0;
}

.cart-item p {
  margin: 10px 0;
}

.quantity-controls{
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-btn{
  margin-left: 20px;
  background-color: red;
  color: white;
}
.quantity{
  padding: 0px 5px 0px 5px;
  margin: 0px 5px;
}

