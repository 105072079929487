/* Checkout.css */

.checkout-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .checkout-form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-top: 10px;
  }
  
  input,
  textarea {
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  .checkout-form{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .purchase-success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4caf50;
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    width: 200px;
    height: 100px;
    z-index: 1000;
    display: none; 
  }
  
  .purchase-success-message.show {
    display: block;
  }
  