.signup-container {
  margin: 0;
  padding: 0;
  background-image: url("../utils/SignupBg1.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-div{
    width: 600px;
    background: #F5FFFA;
    margin: auto;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

  form {
    display: flex;
    width: 60%;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    text-align: left;
  }

  
  label {
    font-weight: bold;
    text-align: left;
  }
  
  input {
    width: 50%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  button {
    background-color: grey;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: grey;
  }
  
  .primary-button {
    background-color: #3498db;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .primary-button:hover {
    background-color: #2980b9;
  }
  
  .primary-button:focus {
    outline: 2px solid #2980b9;
  }
  
