.home-container {
  display: flex;
  align-items: top;
  width: 100%;
  flex-direction: column;
  height: 100vh;
  background-image: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAJQBAQMBIgACEQEDEQH/xAAbAAEBAQEAAwEAAAAAAAAAAAABAAIDBAUGB//EAB4QAQEBAAIDAQEBAAAAAAAAAAABERIhAjFBYVFx/8QAGQEBAQEBAQEAAAAAAAAAAAAAAAECAwQF/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oADAMBAAIRAxEAPwD8sILq85SQiPwH4IkkAqSRUElwFZarKqhUlGQaFawWsX21fTP0aDLWCqCs1qs6NYzQ1WaNAEAvaRAzuddqD4YBSQPYkJzedpAiGIICghEkhQqkYBlrWWlCVQM0Vqs1WsFZsaHtVZZ+t2DBWa5326VmwaxnOhY1QKzYzjdADE1IMFU9GDGpBAihHnlnUw4xtBfAa1MmVAoaqIdFCBLVrN9qqSSgBCqhShcZwNBVZFaVBzwNs2LFrFmjHTBnYrGLG7BIKzgkbxYFZxSN4pBGcTaCvI1azpc3NqHWNIkaTOnQOrRq0Dq0agOhJRA/6FECBUJNuFKuC9dI0CDBjSqqysOLArNgxvFgtc8XF0wcVhWZBxdPGfzpYFc54ni3I1IFcuPinXEQrB1z065jenemNMEa06zqBpQLcEaWhAUM/SCSChCUBJWprAwWNT0sEZTWVYsGcMjUhxYVixSOmKQKxix0xSESs8VxdMXFSufFcXTipMCufG/xOvf6gevLGpydnTTK56dEjW9nWTKI1CzKdCHTrKBpDTohQIakiuIDnSajSCQoglhKoMaxSNQQYpGpDilZwzxakawZrPGLG8WLGaxixvFirXPpOnFC16YsrXB6o0tZIka1BaDWlmERuVazCDRZlIjSBUJgK4zqaghEJghjSIyE+wMKkakVmiRqKNKzVIVGsVKMWNYsEZxY2AZTWISvntOspwe9vVrKgOkQlOhDpZOjLULJEahjMrUVDpBBqGMtRcTTCIRkyNQeJaRoxlqCa1GmY0rOmNYzG1ZUjQhVkpQqAEAsSQPnCE876BSxbM/QMa3WYQbiBmfRnWpUN/hEhnpqVmejFRsskRpqemTFxGiDFjLXiR4mKjUIKst+LTPi0JpjTMOqzrUIhXGTDrKUKCApAHziSed9BJIGoUgMKQyTEg1qGFKzpKQjUMSXEaMSaZa8SkJraSVlqNxJWSkhNajSS4gKSokkCSQP/9k=");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-header {
  border: 1px solid blue;
  height: 50px;
  background-color: antiquewhite;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
}
.right-continer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.image-cont{
    display: flex;
    align-items: top;
    justify-content: center;
    width: 50%;
    height: 100%;
    background-image: url("https://images.pexels.com/photos/919278/pexels-photo-919278.jpeg?auto=compress&cs=tinysrgb&w=600");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.text-cont{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    margin: 50px;
    text-align: center;
    
}
.explore-btn{
    background-color: rgb(24, 42, 42);
    width: 100px;
}